import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

export default class Header extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { text } = this.props;
    return (
      <Container className="header-container">
        <Row>
          <Col className="d-flex justify-content-center">
            <div className="header-title">{text}</div>
          </Col >
        </Row >
      </Container>
    );
  }
}
