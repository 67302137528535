import React, { Component } from 'react';
import { Container, Row, Col, Table } from 'reactstrap';

export default class Results extends Component {
  constructor(props) {
    super(props);
  }

  yelpBusinesses() {
    const { yelp } = this.props;
    return yelp.businesses.map((val, i) => {
      const distance = (parseInt(val.distance) / 1609).toFixed(2);
      return (
        <tr key={i}>
          <td>Business</td>
          <td>{val.price}</td>
          <td><a href={val.url} target="_blank">{val.name}</a></td>
          <td>{val.rating}</td>
          <td>{`${distance}mi`}</td>
        </tr>
      );
    })
  }

  yelpEvents() {
    const { yelp } = this.props;
    return yelp.events.map((event, i) => {
      const free = event.is_free ? true : false;
      const dateTime = event.time_start.split('T');
      const startDate = dateTime[0];
      const time = dateTime[1];
      return (
        <tr key={i}>
          <td>Event</td>
          <td>{free ? 'free' : event.cost}</td>
          <td><a href={event.event_site_url} target="_blank">{event.name}</a></td>
          <td>{`${startDate} / ${time}`}</td>
          <td>{event.location.city}</td>
        </tr>
      );
    })
  }

  eventResults() {
    const { events } = this.props;
    if (events.length > 0) {
      return events.map((event, i) => {
        const ticketStatus = event.dates.status.code;
        let startDate;
        let startTime;
        if (event.dates.start.dateTime) {
          startDate = event.dates.start.dateTime.split('T')[0];
          startTime = event.dates.start.dateTime.split('T')[1];
          startTime = startTime.slice(0, -1);
        } else {
          startDate = event.dates.start.localDate;
          if (event.dates.start.timeTBA)
            startTime = 'TBA';
        }
        let timezone;
        if (event.dates.timezone) {
          timezone = event.dates.timezone.split('/')[1];
          if (timezone.includes('_')) timezone = timezone.split('_').join(' ');
        }
        return (
          <tr key={i}>
            <td>Event</td>
            <td>{ticketStatus}</td>
            <td><a href={event.url} target="_blank">{event.name}</a></td>
            <td>{`${startDate} / ${startTime}`}</td>
            <td>{timezone ? timezone : 'NA'}</td>
          </tr>
        );
      })
    } else {
      return (
        <tr>
          <td></td>
          <td></td>
          <td>SORRY, THERE ARE NO EVENTS THAT MATCH</td>
          <td></td>
          <td></td>
        </tr>
      );
    }
  }

  render() {
    return (
      <Container className="table-container">
        <Row>
          <Col className="">
            <span className="businesses-table-title">Businesses</span>
          </Col>
        </Row>
        <Table className="results-table">
          <thead>
            <tr className="table-headers">
              <th>Type</th>
              <th>Price</th>
              <th>Name</th>
              <th>Rating</th>
              <th>Distance</th>
            </tr>
          </thead>
          <tbody>
            {this.yelpBusinesses()}
          </tbody>
        </Table>
        <span className="events-table-title">Events - TicketMaster</span>
        <Table>
          <thead>
            <tr className="table-headers">
              <th>Type</th>
              <th>Tickets</th>
              <th>Name</th>
              <th>Starts (date/time)</th>
              <th>Zone</th>
            </tr>
          </thead>
          <tbody>
            {this.eventResults()}
          </tbody>
        </Table>
        <span className="events-yelp-title">Events - Yelp</span>
        <Table className="yelp-events-table">
          <thead>
            <tr className="table-headers">
              <th>Type</th>
              <th>Tickets</th>
              <th>Name</th>
              <th>Starts (date/time)</th>
              <th>Zone</th>
            </tr>
          </thead>
          <tbody>
            {this.yelpEvents()}
          </tbody>
        </Table>
      </Container>
    );
  }
}
