import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

export default class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInput: {
        term: '',
        radius: ''
      }
    }
    this.handleInput = this.handleInput.bind(this);
  }

  handleInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'radius') {
      if (/^[\d]*$/g.test(value) && parseInt(value) <= 24) {
        this.setState(prevState => ({ userInput: { ...prevState.userInput, [name]: value } }))
      } else {
        this.setState(prevState => ({ userInput: { ...prevState.userInput, [name]: '' } }));
      }
    } else {
      this.setState(prevState => ({
        userInput: { ...prevState.userInput, [name]: value }
      }))
    }
  }

  render() {
    const { userInput } = this.state;
    return (
      <Container className="input-container" fluid={true}>
        <Row className="search-row justify-content-center">
          <Col xs="4" className="search-col d-flex flex-column align-items-center">
            <span>What are you looking for?</span>
            <input onChange={this.handleInput} className="w-100" value={userInput.term} type="text" name="term" />
          </Col>
        </Row>
        <Row className="radius-row justify-content-center">
          <Col xs="4" className="radius-col d-flex flex-column align-items-center">
            <span>How far? (in miles)</span>
            <small>Max: 24</small>
            <input onChange={this.handleInput} className="w-100" value={userInput.radius} type="text" name="radius" />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="4" className="submit-btn d-flex justify-content-center">
            <button onClick={() => this.props.getData(userInput)} className="btn btn btn-outline-dark">Search</button>
          </Col>
        </Row>
      </Container>
    );
  }
}
