import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

export default class Weather extends Component {
  constructor(props) {
    super(props);
  }

  convertTemperature() {
    return parseInt(this.props.weather.data.current.weather.tp) * 9 / 5 + 32 + 'F';
  }

  render() {
    const { weather, loc } = this.props;
    if (!weather) return null;
    return (
      <Container>
        <Row>
          <Col>
            <div className="weather-title">
              {`${loc.city}, ${loc.stateCode} ${loc.zipCode}`}
              <p className="temp">Temperature: {this.convertTemperature()}</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
