import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

export default class Splash extends Component {

  render() {
    return (
      <Row className="bg-light flex-fill">
        <Col className="">
          <div className="splash d-flex justify-content-center align-items-center">
            Search Around Me
          </div>
        </Col>
      </Row>
    );
  }
}
