import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Keys from './keys';
import Splash from './splash';
import Header from './header';
import Input from './input';
import Results from './results';
import Weather from './weather';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loc: {},
      splash: true,
      tmEvents: [],
      yelp: {
        businesses: [],
        events: []
      },
      submit: false,
      inputExists: false
    }
    this.getData = this.getData.bind(this);
    this.setSubmitTrue = this.setSubmitTrue.bind(this);
    this.setSubmitFalse = this.setSubmitFalse.bind(this);
    this.reverseGeocode = this.reverseGeocode.bind(this);
    this.getWeather = this.getWeather.bind(this);
  }

  componentDidMount() {
    this.getLocation();
    setTimeout(() => this.setState({ splash: false }), 3000);
  }

  getWeather() { // AirVisual
    const { loc } = this.state;
    fetch(`https://cors-anywhere.herokuapp.com/https://api.airvisual.com/v2/city?city=${loc.city}&state=${loc.state}&country=USA&key=${Keys.weather}`)
      .then(res => res.json())
      .then(weather => {
        this.setState(prevState => ({ loc: { ...prevState.loc, weather } }))
      })
  }

  getLocation() { // Google Geolocation
    fetch(`https://www.googleapis.com/geolocation/v1/geolocate?key=${Keys.google}`, {
      method: 'POST', headers: { 'Content-Type': 'application/json' }
    })
      .then(res => res.json())
      .then(loc => {
        this.setState({ loc }, this.reverseGeocode)
      })
      .catch(err => console.error(err));
  }

  reverseGeocode() { // Google Reverse Geocode
    const { loc } = this.state;
    fetch(`https://maps.googleapis.com/maps/api/geocode/json?key=${Keys.google}&latlng=${loc.location.lat},${loc.location.lng}&result_type=postal_code`)
      .then(res => res.json())
      .then(geocode => {
        // const address = geocode.plus_code.compound_code.split(' ');
        const address = geocode.results[0].address_components;
        const city = address.find(val => val.types[0] === 'locality').long_name;
        const state = address.find(val => val.types[0] === 'administrative_area_level_1').long_name;
        const stateCode = address.find(val => val.types[0] === 'administrative_area_level_1').short_name;
        const zipCode = address.find(val => val.types[0] === 'postal_code').long_name;
        // if (address.length === 5) {
        //   city = address[2].split('');
        //   city.pop()
        //   city = address[1] + ' ' + city.join('');
        //   state = address[3].slice(0, -1);
        // } else {
        //   city = address[1].split('');
        //   state = address[2].split('');
        //   city.pop();
        //   state.pop();
        //   city = city.join('');
        //   state = state.join('');
        // }
        this.setState(prevState => ({
          loc: { ...prevState.loc, city, state, stateCode, zipCode }
        }), this.getWeather)
      })
  }

  getData(userInput) { // Yelp
    if (userInput.term.length >= 3 && parseInt(userInput.radius) > 0) {
      this.setState({ inputExists: true });
    } else {
      return;
    }
    const { loc } = this.state;
    const init = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${Keys.yelp}`
      }
    }
    const fetchB = fetch(`https://cors-anywhere.herokuapp.com/https://api.yelp.com/v3/businesses/search?term=${userInput.term}&latitude=${loc.location.lat}&longitude=${loc.location.lng}&radius=${parseInt(userInput.radius) * 1609}`, init);
    const fetchE = fetch(`https://cors-anywhere.herokuapp.com/https://api.yelp.com/v3/events?latitude=${loc.location.lat}&longitude=${loc.location.lng}&radius=${parseInt(userInput.radius)}`, init);
    const ticketMaster = fetch(`https://app.ticketmaster.com/discovery/v2/events.json?stateCode=${loc.stateCode}&keyword=${userInput.term}&apikey=${Keys.tm}`);
    Promise.all([fetchB, fetchE, ticketMaster])
      .then(res => Promise.all(res.map(response => response.json())))
      .then(results => {
        const businesses = results[0].businesses;
        const events = results[1].events;
        let tmEvents;
        if (!results[2].hasOwnProperty('_embedded') || !results[2]._embedded.hasOwnProperty('events')) {
          tmEvents = [];
        } else {
          tmEvents = results[2]._embedded.events;
        }
        this.setState(prevState => ({
          yelp: { ...prevState.yelp, businesses, events },
          tmEvents
        }), this.setSubmitTrue)
      })
      .catch(err => console.error(err));
  }

  setSubmitTrue() {
    this.setState({ submit: true });
  }

  setSubmitFalse() {
    this.setState({ submit: false });
  }

  render() {
    const { splash, submit, yelp, tmEvents, loc } = this.state;
    if (splash) {
      return (
        <Container className="main bg-light d-flex" fluid={true}>
          <Splash />
        </Container>
      );
    }
    return (
      <Container className="main bg-light" fluid={true}>
        <Header text="Search Around Me" />
        <Weather loc={loc} weather={loc.weather} />
        <Input getData={this.getData} />
        {submit ? <Results yelp={yelp} events={tmEvents} /> : null}
      </Container>
    );
  }
}
